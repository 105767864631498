import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Col, FormCheck, FormGroup, Row } from 'react-bootstrap';
import { FormikTextInput } from '../../Form/FormikInput';
import { ContractConfigurationFormType } from './types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import fi from 'date-fns/locale/fi';
import { StyledInput } from '../../Form/Input';
import { Label } from '../../Form/Label';

registerLocale('fi', fi);

export const Party2Signatures = () => {
  const [signeeTime, setSigneeTime] = useState<Date>(new Date());
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<ContractConfigurationFormType>();

  const fiTime = (date: Date) => format(date, 'PPPP', { locale: fi });
  const enTime = (date: Date) => format(date, 'PPPP');

  const handleSetSignaturesForLater = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;

    setFieldValue('party2Signatures.signLater', checked);
    setFieldValue(
      'party2Signatures.signeeName',
      checked ? ' Add the name of the representative of the organization' : ''
    );
    setFieldValue(
      'party2Signatures.signeeTitle',
      checked ? ' Add the title of the representative' : ''
    );
    setFieldValue(
      'party2Signatures.signeeAddress',
      checked ? 'Add the city where the signing will take place' : ''
    );
    setFieldValue(
      'party2Signatures.signeeTime',
      checked ? 'Add the signing place and date' : ''
    );
    setFieldTouched('party2Signatures.signeeName', false);
    setFieldTouched('party2Signatures.signeeTitle', false);
    setFieldTouched('party2Signatures.signeeAddress', false);
  };

  return (
    <>
      <FormGroup as={Row} controlId="party2Signatures.signeeName">
        <Label column xs={4}>
          Toisen sopijapuolen edustajan nimi:
        </Label>
        <Col xs={5}>
          <FormikTextInput
            name="party2Signatures.signeeName"
            placeholder="Etu- ja sukunimi"
            disabled={values.party2Signatures.signLater}
            hideValue={values.party2Signatures.signLater}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row} controlId="party2Signatures.signeeTitle">
        <Label column xs={4}>
          Toisen sopijapuolen edustajan titteli:
        </Label>
        <Col xs={5}>
          <FormikTextInput
            name="party2Signatures.signeeTitle"
            placeholder="Titteli esim. myyntijohtaja"
            disabled={values.party2Signatures.signLater}
            hideValue={values.party2Signatures.signLater}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row} controlId="party2Signatures.signeeAddress">
        <Label column xs={4}>
          Allekirjoituspaikka:
        </Label>
        <Col xs={5}>
          <FormikTextInput
            name="party2Signatures.signeeAddress"
            placeholder="Kaupunki"
            disabled={values.party2Signatures.signLater}
            hideValue={values.party2Signatures.signLater}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row} controlId="party2Signatures.signeeTime">
        <Label column xs={4}>
          Allekirjoitusaika:
        </Label>
        <Col xs={3}>
          <DatePicker
            name="party2Signatures.signeeTime"
            selected={signeeTime}
            onChange={(date: Date) => {
              // check if date exists, prevents input from crashing the app
              if (date) {
                setSigneeTime(date);
                setFieldValue(
                  'party2Signatures.signeeTime',
                  values.lang === 'FI' ? fiTime(date) : enTime(date)
                );
              }
            }}
            dateFormat="dd.MM.yyyy"
            locale="fi"
            customInput={<StyledInput />}
          />
        </Col>
      </FormGroup>
      <FormGroup
        as={Row}
        style={{ marginTop: '3rem', marginBottom: '4rem' }}
        controlId="checkParty2SignatureForLater"
      >
        <Col xs={4} className="text-center">
          <FormCheck
            type="checkbox"
            className="btn-group-vertical"
            checked={values.party2Signatures.signLater}
            onChange={handleSetSignaturesForLater}
          />
        </Col>
        <Col xs={8}>
          <Label>
            Haluan jättää tiedot tyhjäksi. Täydennän ne myöhemmin suoraan
            sopimukseen.
          </Label>
        </Col>
      </FormGroup>
    </>
  );
};
