import { useCallback } from 'react';
import { ContractConfigurationFormType } from '../components/Contract/Configuration/types';
import { cloneDeep } from 'lodash';
import { parseConfigurationData } from '../helpers/contracts';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  setConfigurationRelation,
  setContractGenerationParams,
} from '../features/configuration/configurationSlice';
import toast from 'react-hot-toast';
import { useCreateContractMutation } from '../api/fixed';
import { User } from '@smarterbiz/fixedlegal-shared';
import { format } from 'date-fns';
import fi from 'date-fns/locale/fi';

const dateBasedOnLocale = (lang: string) => {
  if (lang === 'FI') {
    return format(new Date(), 'PPPP', { locale: fi });
  }
  return format(new Date(), 'PPPP');
};

/**
 * Hook for handling contract form submission
 *
 * @note requires Formik and Redux context
 * @returns contract submission functions for authenticated and unauthenticated users
 */
function useSubmitContractForm() {
  const dispatch = useAppDispatch();
  const { configuration } = useAppSelector((state) => state);
  const [createContract] = useCreateContractMutation();

  const unauthenticatedContractSubmission = useCallback(
    async (contractId: string, values: ContractConfigurationFormType) => {
      const formValues = cloneDeep(values);
      formValues.date = dateBasedOnLocale(formValues.date);

      dispatch(setConfigurationRelation(values.relation));

      if (values.relation === 'supplier') {
        formValues.party1.id = 'Add business ID';
        formValues.party1.name = 'Add the name of the organization';
        formValues.party1.address =
          'Add the address of the organization, Add the postal code, city of registration and the country of the organization';
        // if relation is selected as customer, set user details to party2
      } else if (values.relation === 'customer') {
        formValues.party2.id = 'Add business ID';
        formValues.party2.name = 'Add the name of the organization';
        formValues.party2.address =
          'Add the address of the organization, Add the postal code, city of registration and the country of the organization';
      }

      // parses the form values into acceptable format
      const parsedFormValues = parseConfigurationData({
        ...formValues,
        id: contractId,
      });
      // set contract generation parameters to redux
      dispatch(setContractGenerationParams(parsedFormValues));
    },
    [dispatch]
  );

  const authenticatedContractSubmission = useCallback(
    async (
      user: User,
      values: ContractConfigurationFormType,
      isSeparatePurchase?: boolean
    ) => {
      // clone form values
      const formValues = cloneDeep(values);
      formValues.date = dateBasedOnLocale(formValues.date);

      try {
        // if the user is authenticated
        const userAddress = user.companyAddress as unknown as string;
        const addressArray = userAddress.split('\n');
        // if relation is selected as supplier, set user details to party1
        if (values.relation === 'supplier') {
          formValues.party1.id = user.companyId;
          formValues.party1.name = user.companyName;
          formValues.party1.address = addressArray[0];
          formValues.party1.addressCityCode = addressArray[1];
          // if relation is selected as customer, set user details to party2
        } else if (values.relation === 'customer') {
          formValues.party2.id = user.companyId;
          formValues.party2.name = user.companyName;
          formValues.party2.address = addressArray[0];
          formValues.party2.addressCityCode = addressArray[1];
        }

        const generationData = parseConfigurationData({
          ...formValues,
          id: configuration.selectedContractId,
        });

        dispatch(setContractGenerationParams(generationData));

        if (isSeparatePurchase) {
          const res = await createContract({
            ...generationData,
            allowSeparatePurchase: isSeparatePurchase,
          }).unwrap();
          return res;
        }

        const res = await createContract(generationData).unwrap();
        return res;
      } catch (error) {
        toast.error('Virhe sopimuksen generoinnissa!');
        console.error(error);
      }
    },
    []
  );

  return { authenticatedContractSubmission, unauthenticatedContractSubmission };
}

export default useSubmitContractForm;
