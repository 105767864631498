import {
  ContractContextData,
  ContractGenerationData,
  GeneratedContract,
  SelectedInclusion,
} from '@smarterbiz/fixedlegal-shared';
import {
  AppendiceConfigurationFormType,
  ContractConfigurationFormType,
} from '../components/Contract/Configuration/types';
import forage from './localForage/config';

export const getSelectedContractFromStorage = async (key: string) => {
  try {
    const item = await forage.getItem<ContractContextData>(key);
    return item;
  } catch (error) {
    console.error(error);
  }
};

export const getSelectedContractContext = async (key: string) => {
  try {
    const item = await forage.getItem<GeneratedContract>(key);
    return item;
  } catch (error) {
    console.error(error);
  }
};

export const parseConfigurationData = (
  configurationData: ContractConfigurationFormType
): ContractGenerationData => {
  const { relation } = configurationData;

  const parsedData: ContractGenerationData = {
    id: configurationData.id,
    lang: configurationData.lang,
    party1: {
      id:
        relation === 'customer'
          ? configurationData.party2.id
          : configurationData.party1.id,
      name:
        relation === 'customer'
          ? configurationData.party2.name
          : configurationData.party1.name,
      address:
        relation === 'customer'
          ? [
              configurationData.party2.address,
              configurationData.party2.addressCityCode,
            ]
          : [
              configurationData.party1.address,
              configurationData.party1.addressCityCode,
            ],
      signeeName:
        relation === 'customer'
          ? configurationData.party2Signatures.signeeName
          : configurationData.party1Signatures.signeeName,
      signeeTitle:
        relation === 'customer'
          ? configurationData.party2Signatures.signeeTitle
          : configurationData.party1Signatures.signeeTitle,
      signeeTime:
        relation === 'customer'
          ? configurationData.party2Signatures.signeeTime
          : configurationData.party1Signatures.signeeTime,
      signeeAddress:
        relation === 'customer'
          ? configurationData.party2Signatures.signeeAddress
          : configurationData.party1Signatures.signeeAddress,
    },
    party2: {
      id:
        relation === 'customer'
          ? configurationData.party1.id
          : configurationData.party2.id,
      name:
        relation === 'customer'
          ? configurationData.party1.name
          : configurationData.party2.name,
      address:
        relation === 'customer'
          ? [
              configurationData.party1.address,
              configurationData.party1.addressCityCode,
            ]
          : [
              configurationData.party2.address,
              configurationData.party2.addressCityCode,
            ],
      signeeName:
        relation === 'customer'
          ? configurationData.party1Signatures.signeeName
          : configurationData.party2Signatures.signeeName,
      signeeTitle:
        relation === 'customer'
          ? configurationData.party1Signatures.signeeTitle
          : configurationData.party2Signatures.signeeTitle,
      signeeTime:
        relation === 'customer'
          ? configurationData.party1Signatures.signeeTime
          : configurationData.party2Signatures.signeeTime,
      signeeAddress:
        relation === 'customer'
          ? configurationData.party1Signatures.signeeAddress
          : configurationData.party2Signatures.signeeAddress,
    },
    scopes: configurationData.scopes as SelectedInclusion[],
    initialTermInMonths: configurationData.initialTermInMonths,
    renewalTermInMonths: configurationData.renewalTermInMonths,
    fixedTermInMonths: configurationData.fixedTermInMonths,
    noticePeriodMutualInMonths: configurationData.noticePeriodMutualInMonths,
    noticePeriodParty1InMonths: configurationData.noticePeriodParty1InMonths,
    noticePeriodParty2InMonths: configurationData.noticePeriodParty2InMonths,
    noticePeriodParty1InDays: configurationData.noticePeriodParty1InDays,
    noticePeriodParty2InDays: configurationData.noticePeriodParty2InDays,
  };
  return parsedData;
};

export const parseAppendixConfigurationData = (
  configurationData: AppendiceConfigurationFormType
): ContractGenerationData => {
  const { id, lang, party1, scopes, ...rest } = configurationData;
  const parsedData: ContractGenerationData = {
    id: id,
    lang: lang,
    party1: {
      id: party1?.id ?? '',
      name: party1?.name ?? '',
      address: [party1?.address ?? '', party1?.addressCityCode ?? ''],
    },
    party2: {
      id: '',
      name: '',
      address: ['', ''],
    },
    scopes: scopes as SelectedInclusion[],
    ...rest,
  };
  return parsedData;
};

export const parseUserCompanyAddress = (address: string[]): string => {
  const addressAsString = address.toString().split('\n').join(' ');
  return addressAsString;
};
