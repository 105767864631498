import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from '../../redux/hooks';
import { Button } from '../Button';
import { useLazyLogoutQuery } from '../../api/auth';
import { setAuthStatus } from '../../features/auth/authSlice';
import { resetState } from '../../features/configuration/configurationSlice';

type ToggleProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
};

type MenuProps = {
  children?: React.ReactNode;
  className?: string;
  labeledby?: string;
  'aria-labelledby'?: string;
};

const StyledDropdownMenu = styled(Dropdown.Menu)`
  border-radius: 10px;
`;

const StyledDropdownItem = styled.a`
  color: var(--blue);
  font-family: var(--font-avenir);
  cursor: pointer;

  &:hover {
    color: var(--blue);
  }
`;

const DropdownToggle = React.forwardRef(
  ({ children, onClick }: ToggleProps, ref: React.Ref<HTMLButtonElement>) => (
    <Button
      ref={ref}
      buttonsize="xs"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  )
);

const DropdownMenu = React.forwardRef(
  (
    { children, className, 'aria-labelledby': labeledby }: MenuProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <StyledDropdownMenu
        ref={ref}
        className={className}
        aria-labelledby={labeledby}
      >
        <ul className="list-unstyled">{children}</ul>
      </StyledDropdownMenu>
    );
  }
);

type Props = {
  isAuthenticated: boolean | null;
};

export const UserDropdown = ({ isAuthenticated }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logout] = useLazyLogoutQuery();

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(resetState());
      dispatch(setAuthStatus(false));
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={DropdownToggle} id="custom-dropdown">
        {isAuthenticated ? 'Profiilini' : 'Kirjaudu sisään'}
      </Dropdown.Toggle>
      <Dropdown.Menu as={DropdownMenu} align="end">
        <Dropdown.Item
          as={StyledDropdownItem}
          eventKey="1"
          onClick={() => navigate('/profiili')}
        >
          Tilitietoni
        </Dropdown.Item>
        <Dropdown.Item
          as={StyledDropdownItem}
          eventKey="2"
          onClick={() => navigate('/muokkaa-sopimus')}
        >
          Palveluni
        </Dropdown.Item>
        <Dropdown.Item
          as={StyledDropdownItem}
          eventKey="3"
          onClick={() => navigate('/tietopankki')}
        >
          Tietopankki
        </Dropdown.Item>
        <Dropdown.Item
          as={StyledDropdownItem}
          eventKey="4"
          onClick={() => navigate('/info')}
        >
          Info
        </Dropdown.Item>
        <Dropdown.Item
          as={StyledDropdownItem}
          eventKey="5"
          onClick={() => handleLogout()}
        >
          Kirjaudu ulos
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
