import * as scope from './scope';
import { NdaType, Lang, PartyType, Party, ObjectType, PartyCategory } from './common';

export interface ContractClause {
    id: string;
    inclusions?: scope.Inclusion[];
}

export interface Contract {
    id: string;
    lang: Lang;
    title: string;
    tags?: string[];
    isDisabled?: boolean;
    isAppendix?: boolean;
    skipSignatures?: boolean;
    description: string;
    object?: ObjectType;
    nda?: NdaType;
    objectDescription?: string;
    objectDeviation?: string;
    initialTermInMonths?: number;
    renewalTermInMonths?: number;
    fixedTermInMonths?: number;
    noticePeriodMutualInMonths?: number;
    noticePeriodParty1InMonths?: number;
    noticePeriodParty2InMonths?: number;
    noticePeriodParty1InDays?: number;
    noticePeriodParty2InDays?: number;
    basicPriceAppendixSubscriptionPrice?: number; 
    basicPriceAppendixFixedPrice?: number; 
    basicPriceAppendixCapPrice?: number;  
    basicPriceAppendixFixedPricePercentageUponSigning?: number;  
    basicPriceAppendixFixedPricePercentageUponMidDelivery?: number;  
    basicPriceAppendixFixedPricePercentageUponCompletion?: number;  
    basicPriceAppendixHourlyRateProjectManager?: number;  
    basicPriceAppendixHourlyRateProjectCoordinator?: number;  
    basicPriceAppendixHourlyRateAssistant?: number; 
    basicPriceAppendixHourlyRateConsultant?: number;  
    basicPriceAppendixHourlyRateSpecialist?: number;  
    basicPriceAppendixHourlyRateEmployeeA?: number;  
    basicPriceAppendixHourlyRateEmployeeB?: number;  
    basicPriceAppendixHourlyRateEmployeeC?: number;  
    basicPriceAppendixHourlyRateEmployeeD?: number;  
    basicPriceAppendixHourlyRateSeniorPercentage?: number;  
    basicPriceAppendixHourlyRateJuniorPercentage?: number;  
    basicPriceAppendixHourlyRateTraineePercentage?: number;
    basicPriceAppendixHourlyRateFreelancer?: number;  
    basicPriceAppendixHourlyRateManager?: number;  
    basicPriceAppendixHourlyRateTeamLead?: number;  
    basicPriceAppendixHourlyRateEmployee?: number;   

    party1type: PartyType;
    party2type: PartyType;
    party1category?: PartyCategory;
    party2category?: PartyCategory;
    clauses: ContractClause[];
}

export enum ContractVariableKeywords {
    VAR_FIXED_MONTHLY_WORKING_HOURS = "EMPLOYMENT_FIXED_MONTHLY_WORKING_HOURS",
    VAR_FIXED_WEEKLY_WORKING_HOURS = "EMPLOYMENT_FIXED_WEEKLY_WORKING_HOURS",
    VAR_FIXED_HOURLY_WORKING_HOURS = "EMPLOYMENT_FIXED_HOURLY_WORKING_HOURS",
    VAR_EMPLOYMENT_NOTICE_PERIOD = "EMPLOYMENT_NOTICE_PERIOD",
    VAR_NOTICE_PERIOD_MUTUAL_IN_MONTHS = "noticePeriodMutualInMonths",
    VAR_NOTICE_PERIOD_PARTY1_IN_MONTHS = "noticePeriodParty1InMonths",
    VAR_NOTICE_PERIOD_PARTY2_IN_MONTHS = "noticePeriodParty2InMonths",
    VAR_NOTICE_PERIOD_PARTY1_IN_DAYS = "noticePeriodParty1InDays",
    VAR_NOTICE_PERIOD_PARTY2_IN_DAYS = "noticePeriodParty2InDays",
    VAR_RENEWAL_TERM_IN_MONTHS = "renewalTermInMonths",
    VAR_INITIAL_TERM_IN_MONTHS = "initialTermInMonths",
    VAR_FIXED_TERM_IN_MONTHS = "fixedTermInMonths",
    VAR_BASIC_PRICE_APPENDIX_SUBSCRIPTION_PRICE = "basicPriceAppendixSubscriptionPrice",
    VAR_BASIC_PRICE_APPENDIX_FIXED_PRICE = "basicPriceAppendixFixedPrice", 
    VAR_BASIC_PRICE_APPENDIX_CAP_PRICE = "basicPriceAppendixCapPrice", 
    VAR_BASIC_PRICE_APPENDIX_FIXED_PRICE_PERCENTAGE_UPON_SIGNING = "basicPriceAppendixFixedPricePercentageUponSigning", 
    VAR_BASIC_PRICE_APPENDIX_FIXED_PRICE_PERCENTAGE_UPON_MID_DELIVERY = "basicPriceAppendixFixedPricePercentageUponMidDelivery", 
    VAR_BASIC_PRICE_APPENDIX_FIXED_PRICE_PERCENTAGE_UPON_COMPLETION = "basicPriceAppendixFixedPricePercentageUponCompletion", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_PROJECT_MANAGER = "basicPriceAppendixHourlyRateProjectManager", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_PROJECT_COORDINATOR = "basicPriceAppendixHourlyRateProjectCoordinator", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_ASSISTANT = "basicPriceAppendixHourlyRateAssistant", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_CONSULTANT = "basicPriceAppendixHourlyRateConsultant", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_SPECIALIST = "basicPriceAppendixHourlyRateSpecialist", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_EMPLOYEE_A = "basicPriceAppendixHourlyRateEmployeeA", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_EMPLOYEE_B = "basicPriceAppendixHourlyRateEmployeeB", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_EMPLOYEE_C = "basicPriceAppendixHourlyRateEmployeeC", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_EMPLOYEE_D = "basicPriceAppendixHourlyRateEmployeeD", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_SENIOR_PERCENTAGE = "basicPriceAppendixHourlyRateSeniorPercentage", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_JUNIOR_PERCENTAGE = "basicPriceAppendixHourlyRateJuniorPercentage", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_TRAINEE_PERCENTAGE = "basicPriceAppendixHourlyRateTraineePercentage", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_FREELANCER = "basicPriceAppendixHourlyRateFreelancer", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_MANAGER = "basicPriceAppendixHourlyRateManager", 
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_TEAM_LEAD = "basicPriceAppendixHourlyRateTeamLead",
    VAR_BASIC_PRICE_APPENDIX_HOURLY_RATE_EMPLOYEE = "basicPriceAppendixHourlyRateEmployee", 
};

export interface ContractGenerationData {
    id?: string;
    lang: Lang;
    party1: {
        id?: string;
        birthdate?: string;
        name: string;
        address: string[];
        signeeName?: string;
        signeeTitle?: string;
        signeeTime?: string;
        signeeAddress?: string;
    };
    party2: {
        id?: string;
        birthdate?: string;
        name: string;
        address: string[];
        signeeName?: string;
        signeeTitle?: string;
        signeeTime?: string;
        signeeAddress?: string;
    };
    scopes: scope.SelectedInclusion[];
    initialTermInMonths?: number;
    fixedTermInMonths?: number;
    renewalTermInMonths?: number;
    noticePeriodMutualInMonths?: number;
    noticePeriodParty1InMonths?: number;
    noticePeriodParty2InMonths?: number;
    noticePeriodParty1InDays?: number;
    noticePeriodParty2InDays?: number;
    fixedHourlyWorkingHours?: number;
    fixedWeeklyWorkingHours?: number;
    fixedMonthlyWorkingHours?: number;
    employmentNoticePeriod?: number;
    allowSeparatePurchase?: boolean;
    basicPriceAppendixSubscriptionPrice?: number;
    basicPriceAppendixFixedPrice?: number; 
    basicPriceAppendixCapPrice?: number;  
    basicPriceAppendixFixedPricePercentageUponSigning?: number;  
    basicPriceAppendixFixedPricePercentageUponMidDelivery?: number;  
    basicPriceAppendixFixedPricePercentageUponCompletion?: number;  
    basicPriceAppendixHourlyRateProjectManager?: number;  
    basicPriceAppendixHourlyRateProjectCoordinator?: number;  
    basicPriceAppendixHourlyRateAssistant?: number; 
    basicPriceAppendixHourlyRateConsultant?: number;  
    basicPriceAppendixHourlyRateSpecialist?: number;  
    basicPriceAppendixHourlyRateEmployeeA?: number;  
    basicPriceAppendixHourlyRateEmployeeB?: number;  
    basicPriceAppendixHourlyRateEmployeeC?: number;  
    basicPriceAppendixHourlyRateEmployeeD?: number;  
    basicPriceAppendixHourlyRateSeniorPercentage?: number;  
    basicPriceAppendixHourlyRateJuniorPercentage?: number;  
    basicPriceAppendixHourlyRateTraineePercentage?: number;
    basicPriceAppendixHourlyRateFreelancer?: number;  
    basicPriceAppendixHourlyRateManager?: number;  
    basicPriceAppendixHourlyRateTeamLead?: number;  
    basicPriceAppendixHourlyRateEmployee?: number; 
}

export interface ContractContextData {
    id: string,
    lang: Lang,
    title: string,
    desc: string,
    party1: Party,
    party2: Party,
    scopes?: scope.ContractScope[],
    kwInclusions: Map<ContractVariableKeywords, scope.Inclusion[][] | null>,
    initialTermInMonths?: number,
    renewalTermInMonths?: number,
    fixedTermInMonths?: number,
    noticePeriodMutualInMonths?: number,
    noticePeriodParty1InMonths?: number,
    noticePeriodParty2InMonths?: number,
    noticePeriodParty1InDays?: number,
    noticePeriodParty2InDays?: number,
    basicPriceAppendixSubscriptionPrice?: number,
    basicPriceAppendixFixedPrice?: number, 
    basicPriceAppendixCapPrice?: number,
    basicPriceAppendixFixedPricePercentageUponSigning?: number,
    basicPriceAppendixFixedPricePercentageUponMidDelivery?: number,  
    basicPriceAppendixFixedPricePercentageUponCompletion?: number, 
    basicPriceAppendixHourlyRateProjectManager?: number,
    basicPriceAppendixHourlyRateProjectCoordinator?: number,
    basicPriceAppendixHourlyRateAssistant?: number,
    basicPriceAppendixHourlyRateConsultant?: number,  
    basicPriceAppendixHourlyRateSpecialist?: number,  
    basicPriceAppendixHourlyRateEmployeeA?: number, 
    basicPriceAppendixHourlyRateEmployeeB?: number,  
    basicPriceAppendixHourlyRateEmployeeC?: number,  
    basicPriceAppendixHourlyRateEmployeeD?: number,  
    basicPriceAppendixHourlyRateSeniorPercentage?: number,  
    basicPriceAppendixHourlyRateJuniorPercentage?: number,  
    basicPriceAppendixHourlyRateTraineePercentage?: number,
    basicPriceAppendixHourlyRateFreelancer?: number, 
    basicPriceAppendixHourlyRateManager?: number,
    basicPriceAppendixHourlyRateTeamLead?: number, 
    basicPriceAppendixHourlyRateEmployee?: number, 
}

export interface ContractOverview {
    id: string,
    name: string,
    lang: string,
    description: string,
    tags: string[],
    isAppendix: boolean,
    skipSignatures: boolean,
}

export interface GeneratedContract {
    id: string,
    name: string,
    contractId: string,
    companyId: string,
    generatedOn: string,
    generatedBy: string,
    generationParams: string,
}

export interface ContractGenerationContext {
    requestContext: ContractGenerationData,
    contract: Contract
}
