import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Footer from '../Footer';
import { H1 } from '../Headings';
import { PageSection } from '../Sections';
import { BlogListItem } from './BlogListItem';
import { Text } from '../Text';
import { useBlogsQuery } from '../../api/strapi';

const Wrapper = styled.div`
  min-height: calc(100vh - 200px);
`;

export const Blog = () => {
  const { data, isError } = useBlogsQuery();

  return (
    <>
      <Wrapper>
        <PageSection>
          <Row className="justify-content-center">
            <Col sm={10} className="text-center">
              <H1>Fixed Legal Blogi - Lue vinkkejä mm. sopimuksista</H1>
            </Col>
          </Row>
          <Container className="mt-4">
            <Row className="justify-content-center">
              <Col sm={10}>
                <Row>
                  {data && data.length === 0 ? 'Blogeja ei löytynyt' : null}
                  {data &&
                    data.map((blog) => (
                      <BlogListItem key={blog.id} blog={blog} />
                    ))}
                  {isError ? (
                    <div className="text-center">
                      <Text>Virhe Blogeja ladattaessa!</Text>
                    </div>
                  ) : null}
                </Row>
              </Col>
            </Row>
          </Container>
        </PageSection>
      </Wrapper>
      <Footer />
    </>
  );
};
