import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useInfoModal } from '../../../features/modal/hooks';
import { Text } from '../../Text';

type ConfigurationInfoProps = {
  selectedContractId: string;
};

export const ConfigurationInfo = ({
  selectedContractId,
}: ConfigurationInfoProps) => {
  const dispatchInfoModal = useInfoModal();
  const contractsThatReturnNull = [
    'Confidentiality_Agreement_personal',
    'Instructions_on_the_Use_of_AI',
  ];

  if (selectedContractId === 'Data_Processing_Agreement_all') {
    return (
      <div className="d-flex justify-content-between flex-row">
        <Text>
          Tämä sopimus tehdään, kun yritys toimittaa asiakkaalleen palveluita,
          joihin sisältyy henkilötietojen käsittelyä. Kumpi organisaatiosi on?
        </Text>
        <div>
          <AiOutlineInfoCircle
            size="2rem"
            color="#2675f9"
            onClick={() =>
              dispatchInfoModal({
                title: 'Henkilötietojen käsittely',
                content:
                  'Toimittaja on niin sanottu henkilötietojen käsittelijä, joka käsittelee henkilötietoja asiakkaan eli niin sanotun rekisterinpitäjän lukuun. Asiakas on taho, jonka vastuulla olevia henkilötietoja, kuten sen asiakkaiden yhteystietoja, työnhalkijoiden työhakemuksia tai työntekijöiden HR-tietoja toimittaja eli henkilötietojen käsittelijä käsittelee. ',
              })
            }
          />
        </div>
      </div>
    );
  }
  if (selectedContractId === 'Confidentiality_Agreement_B2B_bilateral') {
    return (
      <div className="d-flex justify-content-between flex-row">
        <Text>
          Kumpi sopijapuolista organisaatiosi on sopimussuhteessa? Tietojen
          antaja antaa tietoja toiselle sopijapuolelle eli tietojen
          vastaanottajalle. Jos kumpikin antaa ja vastaanottaa tietoja, valitse
          organisaatioosi enemmän soveltuva. Jos eroa ei ole, voit valita kumman
          tahansa.
        </Text>
        <div>
          <AiOutlineInfoCircle
            size="2rem"
            color="#2675f9"
            onClick={() =>
              dispatchInfoModal({
                title: 'Sopijapuolet',
                content:
                  'Esimerkiksi jos tiedät, että oma organisaatiosi todennäköisesti luovuttaa toiselle sopijapuolelle enemmän luottamuksellista tietoa, valitse "tietojen antaja". Jos kumpikin sopijapuoli antaa ja vastaanottaa tietoa todennäköisesti suurin piirtein saman verran, valitse kumpi tahansa ja jäljempänä sopimuksen painotukseksi neutraali.',
              })
            }
          />
        </div>
      </div>
    );
  }

  if (contractsThatReturnNull.includes(selectedContractId)) {
    return null;
  }

  return <Text>Kumpi organisaatiosi on sopimussuhteessa?</Text>;
};
